/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"
import SEO from "./seo"

type Props = {
  data: {
    page: any
    [key: string]: any
  }
}

type PageProps = {
  data: {
    page: {
      title: string
      slug: string
      excerpt: string
      body: string
    }
  }
}

const Page = ({ data: { page } }: PageProps) => (
  <Layout>
    <SEO title={page.title} description={page.excerpt} />
    <Styled.h2>{page.title}</Styled.h2>
    <section sx={{ 
        my: 5,
        img: {
          objectFit: 'contain',
          width: '100%'
        },
        iframe: {
          objectFit: 'contain',
          width: '100%',
          height: ['360px', '430px', '540px']
        },
      }}
    >
      <MDXRenderer>{page.body}</MDXRenderer>
    </section>
  </Layout>
)

export default ({ data }: Props) => {
  const { page } = data

  return <Page data={{ ...data, page }} />
}
